<template>
	<div class="page">
	  <div class="box b">b</div>
	  <div class="box c">c</div>
	  <div class="box d">d</div>
	  <div class="con"></div>
	</div>
  </template>
  
  <script>
  import { gsap } from "gsap";
  import ScrollTrigger from "gsap/ScrollTrigger";
  export default {
	components: {
	},
	data() {
	  return {
	  };
	},
	computed: {},
	mounted() {
	  gsap.registerPlugin(ScrollTrigger);
	  gsap.to(".b", {
		scrollTrigger: {
		  trigger: ".b",
		  start: "bottom bottom",
		  end: "+=1000",
		  scrub: true,
		//   markers: true,
		  id: "scrub",
          toggleClass: "scrollimgzoomin",
		},
		scale:0.9,
		opacity: 0,
		zIndex: 1,
		x: 0,
		y: 250,
	  },);
  
	  gsap.to(".c", {
		scrollTrigger: {
		  trigger: ".c",
		  start: "bottom bottom",
		  end: "+=1000",
		  scrub: true,
		//   markers: true,
		  id: "scrub",
		},
		opacity: 0,
		scale:0.9,
		zIndex: 2,
		x: 0,
		y: 250,
	  });    
	},
	methods: {},
  };
  </script>
  <style scoped>
  .box.active {
	background-color: blue;
  }
  
  body {
	background-color: #111;
	background-image: linear-gradient(
		rgba(255, 255, 255, 0.07) 2px,
		transparent 2px
	  ),
	  linear-gradient(90deg, rgba(255, 255, 255, 0.07) 2px, transparent 2px),
	  linear-gradient(rgba(255, 255, 255, 0.06) 1px, transparent 1px),
	  linear-gradient(90deg, rgba(255, 255, 255, 0.06) 1px, transparent 1px);
	background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
	background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  }
  
  .box {
	width: 100px;
	height: 100px;
	position: absolute;
	/* left: 100px; */
	z-index: 100;
	line-height: 100px;
	font-size: 50px;
	text-align: center;
  }
  .b {
	top: 600px;
	width: 100%;
	height: 600px;
	background-color: #61aeee;
  }
  .con {
	position: absolute;
	height: 1000px;
	width: 100%;
	top: 1000px;
  }
  
  .c {
	/* opacity: 0; */
	top: 1200px;
	width: 100%;
	height: 1000px;
	background-color: #98c379;
  }
  .d {
	/* opacity: 0; */
	top: 2200px;
	width: 100%;
	height: 1000px;
	background-color: #c678dd;
  }


  </style>
  